import React from 'react'
import { DashboardSkeleton, PublicPageSkeleton, AuthSkeleton } from './components'

const DashboardHome = React.lazy(() =>
  import(/* webpackChunkName: "DashboardHome" */ './scenes/Dashboard/DashboardHome')
)

const DashAssetUpload = React.lazy(() =>
  import(/* webpackChunkName: "DashboardAssetUpload" */ './scenes/Dashboard/DashboardAssetUpload')
)

const DashInstagramBioLinks = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardInstagramBioLinks" */ './scenes/Dashboard/DashboardInstagramBioLinks'
  )
)

const DashContributions = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardContributions" */ './scenes/Dashboard/DashboardContributions'
  )
)

const DashChallenges = React.lazy(() =>
  import(/* webpackChunkName: "DashboardChallenges" */ './scenes/Dashboard/DashboardChallenges')
)

const DashChallenge = React.lazy(() =>
  import(/* webpackChunkName: "DashboardChallenge" */ './scenes/Dashboard/DashboardChallenge')
)

const DashChallengesNew = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardChallengesNew" */ './scenes/Dashboard/DashboardChallengesNew'
  )
)

const DashNotifications = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardNotifications" */ './scenes/Dashboard/DashboardNotifications'
  )
)

const DashReceivedLikes = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardReceivedLikes" */ './scenes/Dashboard/DashboardReceivedLikes'
  )
)

const DashSubscriptions = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSubscriptions" */ './scenes/Dashboard/DashboardSubscriptions'
  )
)

const DashboardBill = React.lazy(() =>
  import(/* webpackChunkName: "DashboardBill" */ './scenes/Dashboard/DashboardBill')
)

const DashboardBillSelection = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardBillSelection" */ './scenes/Dashboard/DashboardBillSelection'
  )
)

const DashboardOpps = React.lazy(() =>
  import(/* webpackChunkName: "DashboardOpps" */ './scenes/Dashboard/DashboardOpps')
)

const DashboardOppsNew = React.lazy(() =>
  import(/* webpackChunkName: "DashboardOppsNew" */ './scenes/Dashboard/DashboardOppsNew')
)

const DashboardOpp = React.lazy(() =>
  import(/* webpackChunkName: "DashboardOpp" */ './scenes/Dashboard/DashboardOpp')
)

const DashOppActivity = React.lazy(() =>
  import(/* webpackChunkName: "DashOppActivity" */ './scenes/Dashboard/DashboardOppActivity')
)

const DashOppPersonActivity = React.lazy(() =>
  import(
    /* webpackChunkName: "DashOppPersonActivity" */ './scenes/Dashboard/DashboardOppPersonActivity'
  )
)

const DashPersonActivity = React.lazy(() =>
  import(/* webpackChunkName: "DashPersonActivity" */ './scenes/Dashboard/DashboardPersonActivity')
)

const DashUpdateMembership = React.lazy(() =>
  import(
    /* webpackChunkName: "DashUpdateMembership" */ './scenes/Dashboard/DashboardUpdatePersonMembership'
  )
)

const DashPlatformEvents = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardPlatformEvents" */ './scenes/Dashboard/DashboardPlatformEvents'
  )
)

const DashEmailActivity = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardEmailActivity" */ './scenes/Dashboard/DashboardEmailActivity'
  )
)

const DashPersonalGoals = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardPersonalGoals" */ './scenes/Dashboard/DashboardPersonalGoals'
  )
)
const DashPersonalGoalNew = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardPersonalGoalNew" */ './scenes/Dashboard/DashboardPersonalGoalNew'
  )
)

const DashStories = React.lazy(() =>
  import(/* webpackChunkName: "DashboardStories" */ './scenes/Dashboard/DashboardStories')
)
const DashStoryNew = React.lazy(() =>
  import(/* webpackChunkName: "DashboardStoryNew" */ './scenes/Dashboard/DashboardStoryNew')
)

const DashStory = React.lazy(() =>
  import(/* webpackChunkName: "DashboardStory" */ './scenes/Dashboard/DashboardStory')
)

const DashboardCamps = React.lazy(() =>
  import(/* webpackChunkName: "DashboardCamps" */ './scenes/Dashboard/DashboardCamps')
)

const DashboardCampNew = React.lazy(() =>
  import(/* webpackChunkName: "DashboardCampNew" */ './scenes/Dashboard/DashboardCampNew')
)

const DashboardCamp = React.lazy(() =>
  import(/* webpackChunkName: "DashboardCamp" */ './scenes/Dashboard/DashboardCamp')
)

const DashboardTeamNew = React.lazy(() =>
  import(/* webpackChunkName: "DashboardTeamNew" */ './scenes/Dashboard/DashboardTeamNew')
)

const DashTeamMembers = React.lazy(() =>
  import(/* webpackChunkName: "DashTeamMembers" */ './scenes/Dashboard/DashboardTeamMembers')
)

const DashTeams = React.lazy(() =>
  import(/* webpackChunkName: "DashboardTeams" */ './scenes/Dashboard/DashboardTeams')
)

const DashSquads = React.lazy(() =>
  import(/* webpackChunkName: "DashboardSquads" */ './scenes/Dashboard/DashboardSquads')
)

const DashTeam = React.lazy(() =>
  import(/* webpackChunkName: "DashboardTeam" */ './scenes/Dashboard/DashboardTeam')
)

const DashTeamGroup = React.lazy(() =>
  import(/* webpackChunkName: "DashboardTeamGroup" */ './scenes/Dashboard/DashboardTeamGroup')
)

const DashNotFound = React.lazy(() =>
  import(/* webpackChunkName: "DashboardNotFound" */ './scenes/Dashboard/DashboardNotFound')
)

const DashAccount = React.lazy(() =>
  import(/* webpackChunkName: "DashboardAccount" */ './scenes/Dashboard/DashboardAccount')
)

const DashUserAccountPrefs = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardUserAccountPrefs" */ './scenes/Dashboard/Account/UserNotificationPreferences'
  )
)

const DashBoost = React.lazy(() =>
  import(/* webpackChunkName: "DashboardBoost" */ './scenes/Dashboard/DashboardBoost')
)

const DashboardOrgs = React.lazy(() =>
  import(/* webpackChunkName: "DashboardOrgs" */ './scenes/Dashboard/DashboardOrgs')
)
const DashboardOrgNew = React.lazy(() =>
  import(/* webpackChunkName: "DashboardOrgNew" */ './scenes/Dashboard/DashboardOrgNew')
)
const DashboardOrg = React.lazy(() =>
  import(/* webpackChunkName: "DashboardOrg" */ './scenes/Dashboard/DashboardOrg')
)
const DashboardOrgMembers = React.lazy(() =>
  import(/* webpackChunkName: "DashOrgMembers" */ './scenes/Dashboard/DashboardOrganisationMembers')
)

const DashboardOrgVerify = React.lazy(() =>
  import(/* webpackChunkName: "DashboardOrgVerify" */ './scenes/Dashboard/DashboardOrgVerify')
)

const DashboardOrgGoals = React.lazy(() =>
  import(/* webpackChunkName: "DashboardOrgGoals" */ './scenes/Dashboard/DashboardOrgGoals')
)
const DashboardOrgGoal = React.lazy(() =>
  import(/* webpackChunkName: "DashboardOrgGoal" */ './scenes/Dashboard/DashboardOrgGoal')
)
const DashboardOrgGoalNew = React.lazy(() =>
  import(/* webpackChunkName: "DashboardOrgGoalNew" */ './scenes/Dashboard/DashboardOrgGoalNew')
)

const DashboardOrgGoalMilestoneNew = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardOrgGoalMilestoneNew" */ './scenes/Dashboard/DashboardOrgGoalMilestoneNew'
  )
)
const DashboardOrgGoalMilestone = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardOrgGoalMilestone" */ './scenes/Dashboard/DashboardOrgGoalMilestone'
  )
)

const DashboardOrgFaq = React.lazy(() =>
  import(/* webpackChunkName: "DashboardOrgFaq" */ './scenes/Dashboard/DashboardOrgFaq')
)
const DashboardOrgFaqs = React.lazy(() =>
  import(/* webpackChunkName: "DashboardOrgFaqs" */ './scenes/Dashboard/DashboardOrgFaqs')
)
const DashboardOrgFaqNew = React.lazy(() =>
  import(/* webpackChunkName: "DashboardOrgFaqNew" */ './scenes/Dashboard/DashboardOrgFaqNew')
)

const DashboardPeopleNew = React.lazy(() =>
  import(/* webpackChunkName: "DashboardPeopleNew" */ './scenes/Dashboard/DashboardPeopleNew')
)

const AsyncDashboardPeople = React.lazy(() =>
  import(/* webpackChunkName: "DashPeopleIndex" */ './scenes/Dashboard/DashboardPeople')
)

const AsyncTerms = React.lazy(() => import(/* webpackChunkName: "Terms" */ './scenes/Terms/Terms'))
const AsyncAboutUs = React.lazy(() =>
  import(/* webpackChunkName: "AboutUs" */ './scenes/AboutUs/index')
)
const AsyncCareers = React.lazy(() =>
  import(/* webpackChunkName: "Careers" */ './scenes/AboutUs/Careers')
)
const AsyncHomePage = React.lazy(() => import(/* webpackChunkName: "Home" */ './scenes/Home'))
const AsyncSefHomePage = React.lazy(() =>
  import(/* webpackChunkName: "SefHome" */ './scenes/Namespaced/Sef/SefHome')
)
const AsyncExtrasPage = React.lazy(() =>
  import(/* webpackChunkName: "Extras" */ './scenes/MarketingPages/Extras')
)

const AsyncFeedPage = React.lazy(() =>
  import(/* webpackChunkName: "FeedPage" */ './scenes/FeedPage/FeedPage')
)

const AsyncNonprofitPricing = React.lazy(() =>
  import(/* webpackChunkName: "NonprofitPricing" */ './scenes/Pricing/Nonprofits')
)
const AsyncBusinessPricing = React.lazy(() =>
  import(/* webpackChunkName: "BusinessPricing" */ './scenes/Pricing/Business')
)
const AsyncEducationPricing = React.lazy(() =>
  import(/* webpackChunkName: "EducationPricing" */ './scenes/Pricing/Education')
)
const AsyncPricingIndex = React.lazy(() =>
  import(/* webpackChunkName: "PricingIndex" */ './scenes/Pricing')
)

const AsyncProcessPlatformPayment = React.lazy(() =>
  import(
    /* webpackChunkName: "ProcessPlatformPayment" */ './scenes/Payments/ProcessPlatformPayment'
  )
)
const AsyncProcessDonation = React.lazy(() =>
  import(/* webpackChunkName: "ProcessDonation" */ './scenes/Payments/ProcessDonation')
)

const AsyncDocsPage = React.lazy(() => import(/* webpackChunkName: "Docs" */ './scenes/Docs/Docs'))
const AsyncPrivacyPolicy = React.lazy(() =>
  import(/* webpackChunkName: "Terms" */ './scenes/PrivacyPolicy')
)

const AsyncNotFound = React.lazy(() =>
  import(/* webpackChunkName: "NotFound" */ './scenes/NotFound/NotFound')
)

const AsyncInstagramBioLinks = React.lazy(() =>
  import(/* webpackChunkName: "InstagramBioLinks" */ './scenes/InstagramBioLinks/InstagramBioLinks')
)

const AsyncOzowDonationCompletion = React.lazy(() =>
  import(/* webpackChunkName: "OzowDonationCompletion" */ './scenes/Ozow/OzowDonationCompletion')
)

const AsyncOzowDonationFailure = React.lazy(() =>
  import(/* webpackChunkName: "OzowDonationFailure" */ './scenes/Ozow/OzowDonationFailure')
)

const AsyncLogin = React.lazy(() => import(/* webpackChunkName: "Login" */ './scenes/Login/Login'))

const AsyncEmailConfirmation = React.lazy(() =>
  import(/* webpackChunkName: "EmailConfirmation" */ './scenes/Login/EmailConfirmation')
)

const AsyncProbation = React.lazy(() =>
  import(/* webpackChunkName: "Probation" */ './scenes/Login/Probation')
)

const AsyncPasswordReset = React.lazy(() =>
  import(/* webpackChunkName: "PasswordReset" */ './scenes/PasswordReset/PasswordReset')
)

const AsyncMagicLogin = React.lazy(() =>
  import(/* webpackChunkName: "MagicLogin" */ './scenes/PasswordReset/MagicLogin')
)

const AsyncPasswordSetup = React.lazy(() =>
  import(/* webpackChunkName: "PasswordSetup" */ './scenes/PasswordSetup/PasswordSetup')
)

const AsyncCampaign = React.lazy(() =>
  import(/* webpackChunkName: "Campaign" */ './scenes/Campaign/Campaign')
)

const AsyncOpportunity = React.lazy(() =>
  import(/* webpackChunkName: "Opportunity" */ './scenes/Opportunities/Opportunity')
)

const AsyncOrgDescription = React.lazy(() =>
  import(/* webpackChunkName: "OrgDescription" */ './scenes/Organisations/OrgDescription')
)

const AsyncOrgFaqs = React.lazy(() =>
  import(/* webpackChunkName: "OrgFaqs" */ './scenes/Organisations/OrgFaqs')
)

const AsyncOrgActivity = React.lazy(() =>
  import(/* webpackChunkName: "OrgActivity" */ './scenes/Organisations/OrgActivity')
)

const AsyncOrgGoal = React.lazy(() =>
  import(/* webpackChunkName: "OrgGoal" */ './scenes/Organisations/OrgGoal')
)
const AsyncOrgGoals = React.lazy(() =>
  import(/* webpackChunkName: "OrgGoals" */ './scenes/Organisations/OrgGoals')
)
const AsyncOrgWebsites = React.lazy(() =>
  import(/* webpackChunkName: "OrgWebsites" */ './scenes/Organisations/OrgWebsites')
)

const AsyncOrgBenefactors = React.lazy(() =>
  import(/* webpackChunkName: "OrgBenefactors" */ './scenes/Organisations/OrgBenefactors')
)

const AsyncOrgCauses = React.lazy(() =>
  import(/* webpackChunkName: "OrgCauses" */ './scenes/Organisations/OrgCauses')
)

const AsyncRequestOrganisationMembership = React.lazy(() =>
  import(
    /* webpackChunkName: "RequestOrganisationMembership" */ './scenes/Organisations/RequestOrganisationMembership'
  )
)

const AsyncManageOrgInvite = React.lazy(() =>
  import(/* webpackChunkName: "ManageOrgInvite" */ './scenes/Organisations/ManageOrgInvite')
)

const AsyncClaimOrganisation = React.lazy(() =>
  import(/* webpackChunkName: "ClaimOrganisation" */ './scenes/Organisations/ClaimOrganisation')
)

const AsyncClaimOrganisationRequest = React.lazy(() =>
  import(
    /* webpackChunkName: "ClaimOrganisationRequest" */ './scenes/Organisations/ClaimOrganisationRequest'
  )
)

const AsyncUser = React.lazy(() => import(/* webpackChunkName: "User" */ './scenes/Users/User'))
const AsyncUserChallenges = React.lazy(() =>
  import(/* webpackChunkName: "UserChallenges" */ './scenes/Users/UserChallenges')
)
const AsyncUserHostedOpps = React.lazy(() =>
  import(/* webpackChunkName: "UserHostedOpps" */ './scenes/Users/UserHostedOpps')
)
const AsyncUserJoinedOpps = React.lazy(() =>
  import(/* webpackChunkName: "UserJoinedOpps" */ './scenes/Users/UserJoinedOpps')
)
const AsyncUserOrgs = React.lazy(() =>
  import(/* webpackChunkName: "UserOrgs" */ './scenes/Users/UserOrgs')
)
const AsyncUserStories = React.lazy(() =>
  import(/* webpackChunkName: "UserStories" */ './scenes/Users/UserStories')
)

const AsyncMoneyDonationConfirmation = React.lazy(() =>
  import(
    /* webpackChunkName: "MoneyDonationConfirmation" */ './scenes/DonationConfirmations/MoneyDonationConfirmation'
  )
)

const AsyncOrgMoneyDonationConfirmation = React.lazy(() =>
  import(
    /* webpackChunkName: "OrgMoneyDonationConfirmation" */ './scenes/DonationConfirmations/OrgMoneyDonationConfirmation'
  )
)

const AsyncGoodsDonationConfirmation = React.lazy(() =>
  import(
    /* webpackChunkName: "GoodsDonationConfirmation" */ './scenes/DonationConfirmations/GoodsDonationConfirmation'
  )
)

const AsyncHostFeedback = React.lazy(() =>
  import(/* webpackChunkName: "HostFeedback" */ './scenes/Feedback/HostFeedback')
)

const AsyncVolunteerFeedback = React.lazy(() =>
  import(/* webpackChunkName: "VolunteerFeedback" */ './scenes/Feedback/VolunteerFeedback')
)

const AsyncPersonalGoodsDonationConfirmationRequest = React.lazy(() =>
  import(
    /* webpackChunkName: "PersonalGoodsDonationConfirmationRequest" */ './scenes/DonationConfirmations/PersonalGoodsDonationConfirmationRequest'
  )
)

const AsyncPersonalMoneyDonationConfirmationRequest = React.lazy(() =>
  import(
    /* webpackChunkName: "PersonalMoneyDonationConfirmationRequest" */ './scenes/DonationConfirmations/PersonalMoneyDonationConfirmationRequest'
  )
)

const AsyncOrgGoodsDonationConfirmation = React.lazy(() =>
  import(
    /* webpackChunkName: "OrgGoodsDonationConfirmation" */ './scenes/DonationConfirmations/OrgGoodsDonationConfirmation'
  )
)

const AsyncOrgGoodsDonationConfirmationRequest = React.lazy(() =>
  import(
    /* webpackChunkName: "OrgGoodsDonationConfirmationRequest" */ './scenes/DonationConfirmations/OrgGoodsDonationConfirmationRequest'
  )
)

const AsyncOrgMoneyDonationConfirmationRequest = React.lazy(() =>
  import(
    /* webpackChunkName: "OrgMoneyDonationConfirmationRequest" */ './scenes/DonationConfirmations/OrgMoneyDonationConfirmationRequest'
  )
)

const AsyncChallengeCheckin = React.lazy(() =>
  import(/* webpackChunkName: "ChallengeCheckin" */ './scenes/Feedback/ChallengeCheckin')
)

const AsyncCompaniesMarketingPage = React.lazy(() =>
  import(/* webpackChunkName: "CompaniesMarketingPage" */ './scenes/MarketingPages/Companies')
)
const AsyncEducationMarketingPage = React.lazy(() =>
  import(/* webpackChunkName: "EducationMarketingPage" */ './scenes/MarketingPages/Education')
)
const AsyncIndividualsMarketingPage = React.lazy(() =>
  import(/* webpackChunkName: "IndividualsMarketingPage" */ './scenes/MarketingPages/Individuals')
)
const AsyncNonprofitsMarketingPage = React.lazy(() =>
  import(/* webpackChunkName: "NonprofitsMarketingPage" */ './scenes/MarketingPages/Nonprofits')
)

const AsyncCampaigns = React.lazy(() =>
  import(/* webpackChunkName: "Campaigns" */ './scenes/MarketingPages/Campaigns')
)
const AsyncCategories = React.lazy(() =>
  import(/* webpackChunkName: "Categories" */ './scenes/MarketingPages/Categories')
)

const AsyncSpecs = React.lazy(() => import(/* webpackChunkName: "Specs" */ './scenes/Misc/Specs'))
const AsyncValues = React.lazy(() =>
  import(/* webpackChunkName: "Values" */ './scenes/MarketingPages/Values')
)

const AsyncChallengeSearch = React.lazy(() =>
  import(/* webpackChunkName: "ChallengeSearch" */ './scenes/Search/ChallengeSearch')
)
const AsyncNonProfitSearch = React.lazy(() =>
  import(/* webpackChunkName: "NonProfitSearch" */ './scenes/Search/NonProfitSearch')
)
const AsyncOppSearch = React.lazy(() =>
  import(/* webpackChunkName: "OppSearch" */ './scenes/Search/OppSearch')
)
const AsyncOrgSearch = React.lazy(() =>
  import(/* webpackChunkName: "OrgSearch" */ './scenes/Search/OrgSearch')
)

const AsyncJoinBrowniePoints = React.lazy(() =>
  import(/* webpackChunkName: "JoinBrowniePoints" */ './scenes/Onboarding/JoinBrowniePoints')
)

const AsyncCompleteOnboarding = React.lazy(() =>
  import(/* webpackChunkName: "CompleteOnboarding" */ './scenes/Onboarding/CompleteOnboarding')
)

const AsyncStories = React.lazy(() =>
  import(/* webpackChunkName: "Stories" */ './scenes/Stories/Stories')
)

const withDashboardSkeleton = WrappedComponent => props => (
  <React.Suspense fallback={<DashboardSkeleton />}>
    <WrappedComponent {...props} />
  </React.Suspense>
)

const withPublicPageSkeleton = WrappedComponent => props => (
  <React.Suspense fallback={<PublicPageSkeleton />}>
    <WrappedComponent {...props} />
  </React.Suspense>
)

const withAuthSkeleton = WrappedComponent => props => (
  <React.Suspense fallback={<AuthSkeleton />}>
    <WrappedComponent {...props} />
  </React.Suspense>
)

///
///
/// Start of DGMT-specific routes
///
///

const DgmtHomePage = React.lazy(() =>
  import(/* webpackChunkName: "DgmtHomePage" */ './scenes/Namespaced/Dgmt/Home')
)

const DgmtDiscoverWebsites = React.lazy(() =>
  import(/* webpackChunkName: "DgmtDiscover" */ './scenes/Namespaced/Dgmt/HomeBackup')
)

const DashDgmtMnos = React.lazy(() =>
  import(/* webpackChunkName: "DgmtMnos" */ './scenes/Namespaced/Dgmt/DashboardMnos')
)
const DashDgmtPbos = React.lazy(() =>
  import(/* webpackChunkName: "DgmtPbos" */ './scenes/Namespaced/Dgmt/DashboardPbos')
)

const DgmtMnoListingRequests = React.lazy(() =>
  import(
    /* webpackChunkName: "DgmtMnoListingRequests" */ './scenes/Namespaced/Dgmt/DashboardMnoListingRequests'
  )
)

const DgmtMnoListingRequest = React.lazy(() =>
  import(
    /* webpackChunkName: "DgmtMnoListingRequest" */ './scenes/Namespaced/Dgmt/DashboardMnoListingRequest'
  )
)

const DgmtPboApplications = React.lazy(() =>
  import(
    /* webpackChunkName: "DgmtPboApplications" */ './scenes/Namespaced/Dgmt/DashboardPboApplications'
  )
)

const DgmtPboApplication = React.lazy(() =>
  import(
    /* webpackChunkName: "DgmtPboApplication" */ './scenes/Namespaced/Dgmt/DashboardPboApplication'
  )
)

const NewPboFormPage = React.lazy(() =>
  import(/* webpackChunkName: "NewPboForm" */ './scenes/Namespaced/Dgmt/NewPboForm')
)
const AdditionalWebsiteFormPage = React.lazy(() =>
  import(
    /* webpackChunkName: "AdditionalWebsiteForm" */ './scenes/Namespaced/Dgmt/AdditionalWebsiteForm'
  )
)

export const DgmtDiscover = withPublicPageSkeleton(DgmtDiscoverWebsites)
export const PboApplication = withPublicPageSkeleton(DgmtPboApplication)
export const PboApplications = withPublicPageSkeleton(DgmtPboApplications)
export const MnoListingRequest = withPublicPageSkeleton(DgmtMnoListingRequest)
export const MnoListingRequests = withPublicPageSkeleton(DgmtMnoListingRequests)
export const DgmtPbos = withPublicPageSkeleton(DashDgmtPbos)
export const DgmtMnos = withPublicPageSkeleton(DashDgmtMnos)
export const DgmtHome = withPublicPageSkeleton(DgmtHomePage)
export const NewPboForm = withPublicPageSkeleton(NewPboFormPage)
export const AdditionalWebsiteForm = withPublicPageSkeleton(AdditionalWebsiteFormPage)

///
///
/// Start of Sef-specific routes
///
///

const DashRules = React.lazy(() =>
  import(/* webpackChunkName: "DashRules" */ './scenes/Dashboard/DashboardRules')
)

const SipOnboardingFormPage = React.lazy(() =>
  import(/* webpackChunkName: "SipOnboardingForm" */ './scenes/Namespaced/Sef/SipOnboardingForm')
)

const EmployeeOnboarding = React.lazy(() =>
  import(/* webpackChunkName: "EmployeeOnboarding" */ './scenes/Namespaced/Sef/EmployeeOnboarding')
)

const EmploymentAgreement = React.lazy(() =>
  import(
    /* webpackChunkName: "EmploymentAgreement" */ './scenes/Namespaced/Sef/EmploymentAgreement'
  )
)

const SefDashManageEmployee = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefManageEmployee" */ './scenes/Namespaced/Sef/DashboardSefManageEmployee'
  )
)

const SefDashBudgetCategory = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefBudgetCategory" */ './scenes/Namespaced/Sef/DashboardSefBudgetCategory'
  )
)

const SefDashBudgetCategories = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefBudgetCategories" */ './scenes/Namespaced/Sef/DashboardSefBudgetCategories'
  )
)

const SefDashBudgetCategoryNew = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefBudgetCategoryNew" */ './scenes/Namespaced/Sef/DashboardSefBudgetCategoryNew'
  )
)

const DashSefAdmins = React.lazy(() =>
  import(/* webpackChunkName: "DashboardSefAdmins" */ './scenes/Namespaced/Sef/DashboardSefAdmins')
)

const DashSefPayroll = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefPayroll" */ './scenes/Namespaced/Sef/DashboardSefPayroll'
  )
)
const DashSefPayrollRequest = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefPayrollRequest" */ './scenes/Namespaced/Sef/DashboardSefPayrollRequest'
  )
)
const SefDashPayrollRequests = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefPayrollRequests" */ './scenes/Namespaced/Sef/DashboardSefPayrollRequests'
  )
)
const SefDashPayrollRequestTransactions = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefPayrollRequestTransactions" */ './scenes/Namespaced/Sef/DashboardSefPayrollRequestTransactions'
  )
)
const DashSefParticipants = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefParticipants" */ './scenes/Namespaced/Sef/DashboardSefParticipants'
  )
)
// const DashSefParticipantTermination = React.lazy(() =>
//   import(
//     /* webpackChunkName: "DashboardSefParticipantTermination" */ './scenes/Namespaced/Sef/DashboardSefParticipantTermination'
//   )
// )
const DashSefParticipantDetails = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefParticipantDetails" */ './scenes/Namespaced/Sef/DashboardSefParticipantDetails'
  )
)
const DashSefSignaturePage = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefSignaturePage" */ './scenes/Namespaced/Sef/DashboardSefSignaturePage'
  )
)

const DashSefParticipantsUpload = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardParticipantsUpload" */ './scenes/Namespaced/Sef/DashboardParticipantsUpload'
  )
)
const DashSefEmploymentAgreement = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardEmploymentAgreement" */ './scenes/Namespaced/Sef/DashboardEmploymentAgreement'
  )
)
const DashSefEmploymentAgreements = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardEmploymentAgreements" */ './scenes/Namespaced/Sef/DashboardEmploymentAgreements'
  )
)
const DashSefEmploymentAgreementNew = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardEmploymentAgreementNew" */ './scenes/Namespaced/Sef/DashboardEmploymentAgreementNew'
  )
)

const SefDashDistrict = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefDistrict" */ './scenes/Namespaced/Sef/DashboardSefDistrict'
  )
)
const DashUploadHistoricalOutputs = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardUploadHistoricalOutputs" */ './scenes/Namespaced/Sef/DashboardUploadHistoricalOutputs'
  )
)

const SefDashDistrictHistory = React.lazy(() =>
  import(
    /* webpackChunkName: "SefDashDistrictHistory" */ './scenes/Namespaced/Sef/DashboardSefDistrictHistory'
  )
)

const SefDashDistricts = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefDistricts" */ './scenes/Namespaced/Sef/DashboardSefDistricts'
  )
)

const SefDashDistrictNew = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefDistrictNew" */ './scenes/Namespaced/Sef/DashboardSefDistrictNew'
  )
)

const SefDashAttendance = React.lazy(() =>
  import(
    /* webpackChunkName: "SefDashAttendance" */ './scenes/Namespaced/Sef/DashboardSefAttendance'
  )
)

const SefDashParticipantAttendance = React.lazy(() =>
  import(
    /* webpackChunkName: "SefDashParticipantAttendance" */ './scenes/Namespaced/Sef/DashboardSefParticipantAttendance'
  )
)

const SefDashMuni = React.lazy(() =>
  import(/* webpackChunkName: "DashboardSefMuni" */ './scenes/Namespaced/Sef/DashboardSefMuni')
)

const SefDashMunis = React.lazy(() =>
  import(/* webpackChunkName: "DashboardSefMunis" */ './scenes/Namespaced/Sef/DashboardSefMunis')
)

const SefDashMuniNew = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefMuniNew" */ './scenes/Namespaced/Sef/DashboardSefMuniNew'
  )
)

const SefDashOutputArea = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefOutputArea" */ './scenes/Namespaced/Sef/DashboardSefOutputArea'
  )
)

const SefDashOutputAreas = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefOutputAreas" */ './scenes/Namespaced/Sef/DashboardSefOutputAreas'
  )
)

const SefDashOutputAreaNew = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefOutputAreaNew" */ './scenes/Namespaced/Sef/DashboardSefOutputAreaNew'
  )
)

const SefDashOutputs = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefOutputs" */ './scenes/Namespaced/Sef/DashboardSefOutputs'
  )
)

const SefDashOutput = React.lazy(() =>
  import(/* webpackChunkName: "DashboardSefOutput" */ './scenes/Namespaced/Sef/DashboardSefOutput')
)

const SefDashOutputNew = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefOutputNew" */ './scenes/Namespaced/Sef/DashboardSefOutputNew'
  )
)

const SefDashOutputIndicator = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefOutputIndicator" */ './scenes/Namespaced/Sef/DashboardSefOutputIndicator'
  )
)

const SefDashOutputIndicatorHistory = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefOutputIndicatorHistory" */ './scenes/Namespaced/Sef/DashboardSefOutputIndicatorHistory'
  )
)

const SefDashOutputIndicators = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefOutputIndicators" */ './scenes/Namespaced/Sef/DashboardSefOutputIndicators'
  )
)

const SefDashOutputIndicatorNew = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefOutputIndicatorNew" */ './scenes/Namespaced/Sef/DashboardSefOutputIndicatorNew'
  )
)

const SefDashPartners = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefPartners" */ './scenes/Namespaced/Sef/DashboardSefPartners'
  )
)

const SefDashPartnerNew = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefPartnerNew" */ './scenes/Namespaced/Sef/DashboardSefPartnerNew'
  )
)

const SefDashPlace = React.lazy(() =>
  import(/* webpackChunkName: "DashboardSefPlace" */ './scenes/Namespaced/Sef/DashboardSefPlace')
)

const SefDashPlaces = React.lazy(() =>
  import(/* webpackChunkName: "DashboardSefPlaces" */ './scenes/Namespaced/Sef/DashboardSefPlaces')
)

const SefDashPlaceNew = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefPlaceNew" */ './scenes/Namespaced/Sef/DashboardSefPlaceNew'
  )
)

const SefDashPurchaseRequest = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefPurchaseRequest" */ './scenes/Namespaced/Sef/DashboardSefPurchaseRequest'
  )
)

const SefDashPurchaseRequests = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefPurchaseRequests" */ './scenes/Namespaced/Sef/DashboardSefPurchaseRequests'
  )
)

const SefDashPurchaseRequestNew = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefPurchaseRequestNew" */ './scenes/Namespaced/Sef/DashboardSefPurchaseRequestNew'
  )
)

const SefDashSupplier = React.lazy(() =>
  import(/* webpackChunkName: "SefSupplier" */ './scenes/Namespaced/Sef/DashboardSefSupplier')
)

const SefDashSuppliers = React.lazy(() =>
  import(/* webpackChunkName: "SefSuppliers" */ './scenes/Namespaced/Sef/DashboardSefSuppliers')
)

const SefDashSupplierNew = React.lazy(() =>
  import(
    /* webpackChunkName: "SefDashSupplierNew" */ './scenes/Namespaced/Sef/DashboardSefSupplierNew'
  )
)

const SefDashWard = React.lazy(() =>
  import(/* webpackChunkName: "DashboardSefWard" */ './scenes/Namespaced/Sef/DashboardSefWard')
)

const SefDashWards = React.lazy(() =>
  import(/* webpackChunkName: "DashboardSefWards" */ './scenes/Namespaced/Sef/DashboardSefWards')
)

const SefDashWardNew = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefWardNew" */ './scenes/Namespaced/Sef/DashboardSefWardNew'
  )
)

const SefDashWorkspace = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefWorkspace" */ './scenes/Namespaced/Sef/DashboardSefWorkspace'
  )
)

const SefDashWorkspaces = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefWorkspaces" */ './scenes/Namespaced/Sef/DashboardSefWorkspaces'
  )
)

const SefDashWorkspaceNew = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefWorkspaceNew" */ './scenes/Namespaced/Sef/DashboardSefWorkspaceNew'
  )
)

const SefDashWorksite = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefWorksite" */ './scenes/Namespaced/Sef/DashboardSefWorksite'
  )
)

const SefDashWorksiteHistory = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefWorksiteHistory" */ './scenes/Namespaced/Sef/DashboardSefWorksiteHistory'
  )
)

const SefDashWorksites = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefWorksites" */ './scenes/Namespaced/Sef/DashboardSefWorksites'
  )
)
const SefDashWorksiteEmployees = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefWorksiteEmployees" */ './scenes/Namespaced/Sef/DashboardSefWorksiteEmployees'
  )
)

const SefDashWorksiteNew = React.lazy(() =>
  import(
    /* webpackChunkName: "DashboardSefWorksiteNew" */ './scenes/Namespaced/Sef/DashboardSefWorksiteNew'
  )
)

export const SefEmployeeOnboarding = withPublicPageSkeleton(EmployeeOnboarding)
export const SipOnboardingForm = withPublicPageSkeleton(SipOnboardingFormPage)
export const SefEmploymentAgreement = withPublicPageSkeleton(EmploymentAgreement)
export const SefManageEmployee = withDashboardSkeleton(SefDashManageEmployee)
export const SefBudgetCategory = withDashboardSkeleton(SefDashBudgetCategory)
export const SefBudgetCategories = withDashboardSkeleton(SefDashBudgetCategories)
export const SefBudgetCategoryNew = withDashboardSkeleton(SefDashBudgetCategoryNew)
export const SefAdmins = withDashboardSkeleton(DashSefAdmins)
export const SefPayroll = withDashboardSkeleton(DashSefPayroll)
export const SefPayrollRequest = withDashboardSkeleton(DashSefPayrollRequest)
export const SefPayrollRequests = withDashboardSkeleton(SefDashPayrollRequests)
export const SefPayrollRequestTransactions = withDashboardSkeleton(
  SefDashPayrollRequestTransactions
)
export const SefParticipants = withDashboardSkeleton(DashSefParticipants)
export const SefParticipantDetails = withDashboardSkeleton(DashSefParticipantDetails)
// export const SefParticipantTermination = withDashboardSkeleton(DashSefParticipantTermination)
export const SefParticipantsUpload = withDashboardSkeleton(DashSefParticipantsUpload)
export const SefEmploymentAgreementEdit = withDashboardSkeleton(DashSefEmploymentAgreement)
export const SefEmploymentAgreements = withDashboardSkeleton(DashSefEmploymentAgreements)
export const SefEmploymentAgreementNew = withDashboardSkeleton(DashSefEmploymentAgreementNew)
export const SefSignaturePage = withDashboardSkeleton(DashSefSignaturePage)
export const SefUploadHistoricalOutputs = withDashboardSkeleton(DashUploadHistoricalOutputs)
export const SefDistrict = withDashboardSkeleton(SefDashDistrict)
export const SefDistrictHistory = withDashboardSkeleton(SefDashDistrictHistory)
export const SefDistricts = withDashboardSkeleton(SefDashDistricts)
export const SefDistrictNew = withDashboardSkeleton(SefDashDistrictNew)
export const SefAttendance = withDashboardSkeleton(SefDashAttendance)
export const SefSetParticipantAttendance = withDashboardSkeleton(SefDashParticipantAttendance)
export const SefMuni = withDashboardSkeleton(SefDashMuni)
export const SefMunis = withDashboardSkeleton(SefDashMunis)
export const SefMuniNew = withDashboardSkeleton(SefDashMuniNew)
export const SefOutputArea = withDashboardSkeleton(SefDashOutputArea)
export const SefOutputAreas = withDashboardSkeleton(SefDashOutputAreas)
export const SefOutputs = withDashboardSkeleton(SefDashOutputs)
export const SefOutput = withDashboardSkeleton(SefDashOutput)
export const SefOutputNew = withDashboardSkeleton(SefDashOutputNew)
export const SefOutputAreaNew = withDashboardSkeleton(SefDashOutputAreaNew)
export const SefOutputIndicator = withDashboardSkeleton(SefDashOutputIndicator)
export const SefOutputIndicatorHistory = withDashboardSkeleton(SefDashOutputIndicatorHistory)
export const SefOutputIndicators = withDashboardSkeleton(SefDashOutputIndicators)
export const SefOutputIndicatorNew = withDashboardSkeleton(SefDashOutputIndicatorNew)
export const SefPartners = withDashboardSkeleton(SefDashPartners)
export const SefPartnerNew = withDashboardSkeleton(SefDashPartnerNew)
export const SefPlace = withDashboardSkeleton(SefDashPlace)
export const SefPlaces = withDashboardSkeleton(SefDashPlaces)
export const SefPlaceNew = withDashboardSkeleton(SefDashPlaceNew)
export const SefPurchaseRequest = withDashboardSkeleton(SefDashPurchaseRequest)
export const SefPurchaseRequests = withDashboardSkeleton(SefDashPurchaseRequests)
export const SefPurchaseRequestNew = withDashboardSkeleton(SefDashPurchaseRequestNew)
export const SefSupplier = withDashboardSkeleton(SefDashSupplier)
export const SefSuppliers = withDashboardSkeleton(SefDashSuppliers)
export const SefSupplierNew = withDashboardSkeleton(SefDashSupplierNew)
export const SefWard = withDashboardSkeleton(SefDashWard)
export const SefWards = withDashboardSkeleton(SefDashWards)
export const SefWardNew = withDashboardSkeleton(SefDashWardNew)
export const SefWorkspace = withDashboardSkeleton(SefDashWorkspace)
export const SefWorkspaces = withDashboardSkeleton(SefDashWorkspaces)
export const SefWorkspaceNew = withDashboardSkeleton(SefDashWorkspaceNew)
export const SefWorksite = withDashboardSkeleton(SefDashWorksite)
export const SefWorksiteHistory = withDashboardSkeleton(SefDashWorksiteHistory)
export const SefWorksites = withDashboardSkeleton(SefDashWorksites)
export const SefWorksiteEmployees = withDashboardSkeleton(SefDashWorksiteEmployees)
export const SefWorksiteNew = withDashboardSkeleton(SefDashWorksiteNew)

///
///
/// End of Sef-specific routes
///
///

export const Terms = withPublicPageSkeleton(AsyncTerms)
export const AboutUs = withPublicPageSkeleton(AsyncAboutUs)
export const Careers = withPublicPageSkeleton(AsyncCareers)
export const HomePage = withPublicPageSkeleton(AsyncHomePage)
export const SefHomePage = withPublicPageSkeleton(AsyncSefHomePage)
export const ExtrasPage = withPublicPageSkeleton(AsyncExtrasPage)
export const FeedPage = withPublicPageSkeleton(AsyncFeedPage)
export const NonprofitPricing = withPublicPageSkeleton(AsyncNonprofitPricing)
export const BusinessPricing = withPublicPageSkeleton(AsyncBusinessPricing)
export const EducationPricing = withPublicPageSkeleton(AsyncEducationPricing)
export const Pricing = withPublicPageSkeleton(AsyncPricingIndex)
export const ProcessDonation = withPublicPageSkeleton(AsyncProcessDonation)
export const ProcessPlatformPayment = withPublicPageSkeleton(AsyncProcessPlatformPayment)
export const PrivacyPolicy = withPublicPageSkeleton(AsyncPrivacyPolicy)
export const DocsPage = withPublicPageSkeleton(AsyncDocsPage)
export const NotFound = withPublicPageSkeleton(AsyncNotFound)
export const InstagramBioLinks = withPublicPageSkeleton(AsyncInstagramBioLinks)
export const Campaign = withPublicPageSkeleton(AsyncCampaign)
export const EmailConfirmation = withPublicPageSkeleton(AsyncEmailConfirmation)
export const Probation = withPublicPageSkeleton(AsyncProbation)
export const Opportunity = withPublicPageSkeleton(AsyncOpportunity)

export const OrgActivity = withPublicPageSkeleton(AsyncOrgActivity)
export const OrgBenefactors = withPublicPageSkeleton(AsyncOrgBenefactors)
export const OrgCauses = withPublicPageSkeleton(AsyncOrgCauses)
export const OrgDescription = withPublicPageSkeleton(AsyncOrgDescription)
export const OrgFaqs = withPublicPageSkeleton(AsyncOrgFaqs)
export const OrgGoal = withPublicPageSkeleton(AsyncOrgGoal)
export const OrgGoals = withPublicPageSkeleton(AsyncOrgGoals)
export const OrgWebsites = withPublicPageSkeleton(AsyncOrgWebsites)

export const ManageOrgInvite = withPublicPageSkeleton(AsyncManageOrgInvite)
export const RequestOrganisationMembership = withPublicPageSkeleton(
  AsyncRequestOrganisationMembership
)
export const ClaimOrganisation = withPublicPageSkeleton(AsyncClaimOrganisation)
export const ClaimOrganisationRequest = withPublicPageSkeleton(AsyncClaimOrganisationRequest)
export const HostFeedback = withPublicPageSkeleton(AsyncHostFeedback)
export const MoneyDonationConfirmation = withPublicPageSkeleton(AsyncMoneyDonationConfirmation)
export const OrgMoneyDonationConfirmation = withPublicPageSkeleton(
  AsyncOrgMoneyDonationConfirmation
)

export const GoodsDonationConfirmation = withPublicPageSkeleton(AsyncGoodsDonationConfirmation)
export const OrgGoodsDonationConfirmation = withPublicPageSkeleton(
  AsyncOrgGoodsDonationConfirmation
)
export const VolunteerFeedback = withPublicPageSkeleton(AsyncVolunteerFeedback)
export const PersonalGoodsDonationConfirmationRequest = withPublicPageSkeleton(
  AsyncPersonalGoodsDonationConfirmationRequest
)
export const PersonalMoneyDonationConfirmationRequest = withPublicPageSkeleton(
  AsyncPersonalMoneyDonationConfirmationRequest
)
export const OrgGoodsDonationConfirmationRequest = withPublicPageSkeleton(
  AsyncOrgGoodsDonationConfirmationRequest
)
export const OrgMoneyDonationConfirmationRequest = withPublicPageSkeleton(
  AsyncOrgMoneyDonationConfirmationRequest
)
export const ChallengeCheckin = withPublicPageSkeleton(AsyncChallengeCheckin)
export const Specs = withPublicPageSkeleton(AsyncSpecs)

export const CompaniesMarketingPage = withPublicPageSkeleton(AsyncCompaniesMarketingPage)
export const EducationMarketingPage = withPublicPageSkeleton(AsyncEducationMarketingPage)
export const IndividualsMarketingPage = withPublicPageSkeleton(AsyncIndividualsMarketingPage)
export const NonprofitsMarketingPage = withPublicPageSkeleton(AsyncNonprofitsMarketingPage)
export const Campaigns = withPublicPageSkeleton(AsyncCampaigns)
export const Categories = withPublicPageSkeleton(AsyncCategories)
export const ChallengeSearch = withPublicPageSkeleton(AsyncChallengeSearch)
export const NonProfitSearch = withPublicPageSkeleton(AsyncNonProfitSearch)
export const OppSearch = withPublicPageSkeleton(AsyncOppSearch)
export const OrgSearch = withPublicPageSkeleton(AsyncOrgSearch)
export const Values = withPublicPageSkeleton(AsyncValues)

export const JoinBrowniePoints = withAuthSkeleton(AsyncJoinBrowniePoints)
export const CompleteOnboarding = withDashboardSkeleton(AsyncCompleteOnboarding)

export const Stories = withDashboardSkeleton(AsyncStories)

export const OzowDonationCompletion = withPublicPageSkeleton(AsyncOzowDonationCompletion)
export const OzowDonationFailure = withPublicPageSkeleton(AsyncOzowDonationFailure)

export const Login = withAuthSkeleton(AsyncLogin)
export const PasswordReset = withAuthSkeleton(AsyncPasswordReset)
export const MagicLogin = withAuthSkeleton(AsyncMagicLogin)
export const PasswordSetup = withAuthSkeleton(AsyncPasswordSetup)

export const Dashboard = withDashboardSkeleton(DashboardHome)
export const Rules = withDashboardSkeleton(DashRules)
export const DashboardAssetUpload = withDashboardSkeleton(DashAssetUpload)
export const DashboardInstagramBioLinks = withDashboardSkeleton(DashInstagramBioLinks)
export const DashboardBilling = withDashboardSkeleton(DashboardBill)
export const DashboardBillingSelection = withDashboardSkeleton(DashboardBillSelection)
export const DashboardContributions = withDashboardSkeleton(DashContributions)
export const DashboardChallenges = withDashboardSkeleton(DashChallenges)
export const DashboardChallenge = withDashboardSkeleton(DashChallenge)
export const DashboardNewChallenges = withDashboardSkeleton(DashChallengesNew)
export const DashboardReceivedLikes = withDashboardSkeleton(DashReceivedLikes)
export const DashboardNotifications = withDashboardSkeleton(DashNotifications)
export const DashboardSubscriptions = withDashboardSkeleton(DashSubscriptions)
export const DashboardOpportunities = withDashboardSkeleton(DashboardOpps)
export const DashboardNewOpportunities = withDashboardSkeleton(DashboardOppsNew)
export const DashboardOpportunity = withDashboardSkeleton(DashboardOpp)
export const DashboardOppActivity = withDashboardSkeleton(DashOppActivity)
export const DashboardOppPersonActivity = withDashboardSkeleton(DashOppPersonActivity)
export const DashboardPersonActivity = withDashboardSkeleton(DashPersonActivity)
export const DashboardUpdateMembership = withDashboardSkeleton(DashUpdateMembership)
export const DashboardCampaign = withDashboardSkeleton(DashboardCamp)
export const DashboardCampaigns = withDashboardSkeleton(DashboardCamps)
export const DashboardNewCampaign = withDashboardSkeleton(DashboardCampNew)
export const DashboardOrganization = withDashboardSkeleton(DashboardOrg)
export const DashboardOrganisationMembers = withDashboardSkeleton(DashboardOrgMembers)
export const DashboardOrganisationVerify = withDashboardSkeleton(DashboardOrgVerify)
export const DashboardOrganisationFaq = withDashboardSkeleton(DashboardOrgFaq)
export const DashboardOrganisationFaqs = withDashboardSkeleton(DashboardOrgFaqs)
export const DashboardOrganisationFaqNew = withDashboardSkeleton(DashboardOrgFaqNew)
export const DashboardOrganisationGoal = withDashboardSkeleton(DashboardOrgGoal)
export const DashboardOrganisationGoals = withDashboardSkeleton(DashboardOrgGoals)
export const DashboardOrganisationGoalNew = withDashboardSkeleton(DashboardOrgGoalNew)
export const DashboardOrganisationGoalMilestone = withDashboardSkeleton(DashboardOrgGoalMilestone)
export const DashboardOrganisationGoalMilestoneNew = withDashboardSkeleton(
  DashboardOrgGoalMilestoneNew
)
export const DashboardOrganizations = withDashboardSkeleton(DashboardOrgs)
export const DashboardBoost = withDashboardSkeleton(DashBoost)
export const DashboardNewOrganization = withDashboardSkeleton(DashboardOrgNew)
export const DashboardNewPeople = withDashboardSkeleton(DashboardPeopleNew)
export const DashboardPeople = withDashboardSkeleton(AsyncDashboardPeople)
export const DashboardNewTeam = withDashboardSkeleton(DashboardTeamNew)
export const DashboardSquads = withDashboardSkeleton(DashSquads)
export const DashboardTeams = withDashboardSkeleton(DashTeams)
export const DashboardTeam = withDashboardSkeleton(DashTeam)
export const DashboardTeamMembers = withDashboardSkeleton(DashTeamMembers)
export const DashboardTeamGroup = withDashboardSkeleton(DashTeamGroup)
export const DashboardAccount = withDashboardSkeleton(DashAccount)
export const DashboardUserAccountPrefs = withDashboardSkeleton(DashUserAccountPrefs)
export const DashboardNotFound = withDashboardSkeleton(DashNotFound)
export const DashboardPlatformEvents = withDashboardSkeleton(DashPlatformEvents)
export const DashboardEmailActivity = withDashboardSkeleton(DashEmailActivity)
export const DashboardPersonalGoals = withDashboardSkeleton(DashPersonalGoals)
export const DashboardPersonalGoalNew = withDashboardSkeleton(DashPersonalGoalNew)
export const DashboardStories = withDashboardSkeleton(DashStories)
export const DashboardStory = withDashboardSkeleton(DashStory)
export const DashboardStoryNew = withDashboardSkeleton(DashStoryNew)

export const User = withPublicPageSkeleton(AsyncUser)
export const UserChallenges = withPublicPageSkeleton(AsyncUserChallenges)
export const HostedOpps = withPublicPageSkeleton(AsyncUserHostedOpps)
export const JoinedOpps = withPublicPageSkeleton(AsyncUserJoinedOpps)
export const UserStories = withPublicPageSkeleton(AsyncUserStories)
export const UserOrgs = withPublicPageSkeleton(AsyncUserOrgs)
